<template>
  <a-row class="content-fix" type="flex">
    <!-- {{ $route.params.id }} -->
    <!-- {{ langTab }} -->
    <!-- {{ form.id }} -->
    <spinner v-if="loading" />

    <a-col v-show="!loading" class="height100 flex-1">
      <!-- {{ form.content }} -->
      <a-row class="btn-row">
        <button
          class="tab-btn"
          :class="{ active: tab === 'content' }"
          @click="tab = 'content'"
        >
          <a-icon type="project" />Содержимое
        </button>

        <button
          class="tab-btn"
          :class="{ active: tab === 'photo' }"
          @click="tab = 'photo'"
        >
          <a-icon type="picture" />Фото
        </button>
      </a-row>

      <div v-show="tab === 'content'">
        <!-- lang tabs -->
        <a-row align="middle" class="flex-baseline sm-mb">
          <a-col :span="4" class="form_title"> Язык: </a-col>
          <a-col :span="18">
            <!-- lang translit tabs -->
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
              <a-button v-if="langTab === 'oz'" @click.stop="translitAction">
                Перевести копию на
                {{ langTab === "uz" ? "кирилицу" : "латиницу" }}
              </a-button>
            </a-row>
          </a-col>
        </a-row>

        <!-- title -->
        <a-row
          :class="{ 'form-group--error': emptyTitle }"
          align="middle"
          class="flex-baseline"
        >
          <a-col :span="4" class="form_title"> Заголовок: </a-col>
          <a-col :span="20" class="sm-mb">
            <a-input
              v-model="form.title[langTab]"
              placeholder="Введите заголовок"
              class="form_title-input"
            />
            <div v-if="isTitleNull" class="error-text">
              Введите заголовок на одном из языков.
            </div>
          </a-col>
        </a-row>

        <!-- status toggle -->
        <a-row style="margin: 15px 0">
          <a-col :span="4" class="form_title"> Статус: </a-col>
          <a-switch v-model="form.is_active" />
        </a-row>

        <!-- position -->
        <a-row align="middle" class="flex-baseline input-form__group">
          <a-col :span="4" class="form_title">
            {{ $t("MenuPosition") }}:
          </a-col>
          <a-col :span="12">
            <a-input-number
              v-model="form.position"
              placeholder="Введите позицию"
            />
            <div v-if="!form.position" class="error-text">Введите позицию</div>
          </a-col>
        </a-row>
      </div>

      <!-- photo tab -->
      <div v-show="tab === 'photo'">
        <!-- upload photo -->
        <a-row align="middle">
          <a-col :span="3" class="form_title"> Изображения </a-col>
          <a-col :span="12">
            <image-upload
              v-if="!imageUploading"
              :list="photoList"
              :selected-image="main_image"
              accept="image/jpeg, image/png"
              multiple
              upload-multiple
              :with-cropper="false"
              has-method
              @change="handleImages"
              @drag-update="updateFilesPosition"
              @set-main-image="activeImage"
              @delete="deleteImage"
            />
          </a-col>
        </a-row>
      </div>

      <a-row
        v-if="tab === 'content'"
        class="form-buttons__action"
        style="margin-top: 20px"
        type="flex"
      >
        <a-col :span="4" style="padding-right: 8px" />

        <a-col>
          <a-button
            :disabled="pending"
            @click="
              $router.push({
                name: 'flayers',
                params: {
                  act_id: $route.params.act_id,
                  type: $route.params.type
                }
              })
            "
          >
            <a-icon type="close" />Отмена
          </a-button>

          <a-button
            :loading="pending"
            style="margin-left: 10px"
            type="primary"
            @click="submitNews((noRouterBack = true))"
          >
            <a-icon type="check" />
            {{ form.id ? "Сохранить" : "Добавить" }}
          </a-button>

          <a-button
            :loading="pending2"
            style="margin-left: 10px"
            type="primary"
            @click="submitNews((noRouterBack = false))"
          >
            <a-icon type="check" />
            {{ form.id ? "Сохранить и выйти" : "Добавить" }}
          </a-button>
        </a-col>
      </a-row>
    </a-col>

    <spoiler-modal
      v-show="!!spoiler.modalVisibility"
      v-model="spoiler"
      @ok="appendToEditor"
      @cancel="closeSpoilerModal"
    />
  </a-row>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
// import VueTagsInput from "@johmun/vue-tags-input"
import spoiler from "@/mixins/spoiler.js"
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css"

const newsDefaultFields = () => ({
  title: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  position: 1,
  is_active: true,
  images: []
})

export default {
  name: "News",

  components: {
    "image-upload": () => import("@/components/utils/image-upload"),
    spoilerModal: () =>
      import("@/components/directory/modal-components/modal-spoiler")
  },

  mixins: [spoiler],

  data() {
    return {
      commentsList: null,
      tab: "content",
      langTab: $langPrefix,
      imageUploading: false,
      form: { ...newsDefaultFields() },
      fileList: [],
      image_List: [],
      pending: false,
      pending2: false,
      loading: false,
      tabPane: null,
      main_image: null,
      emptyTitle: false
    }
  },

  computed: {
    isTitleNull() {
      return !!(
        !this.form.title.uz &&
        !this.form.title.oz &&
        !this.form.title.ru &&
        !this.form.title.en
      )
    },
    photoList() {
      return this.form && this.form.images
    },
    getKey() {
      return this.langTab
    }
  },

  watch: {
    $route: {
      handler() {
        this.fetchData()
      },
      immediate: true
    }
  },

  async created() {
    this.loading = true
    this.form = {
      ...newsDefaultFields()
    }
    this.loading = false
  },

  methods: {
    async deleteImage(val) {
      await this.$api.delete(
        "/admin/common/activity/card/img/" + val + "/delete/"
      )
    },

    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab === "uz" ? "oz" : "uz",
          methodName = this.langTab === "uz" ? "toKirill" : "toLatin"

        if (this.form.title[from]) {
          this.form.title[to] = this[methodName](this.form.title[from])
        }
      } catch (error) {
        this.$sentry.captureMessage(error)
      }
    },

    updateFilesPosition(files) {
      let promises = []
      // let otherImages = new FormData()
      let images_order = []
      // const mainImage = this.fileList.find(
      //   (item) => item.id && item.uzid === this.main_image
      // )
      Array.from(files)
        .filter((item) => item.id && item.uzid !== this.main_image)
        .map((item, index) => {
          // console.log("item", item)
          // console.log("index", index)
          images_order = [
            ...images_order,
            { image_id: item.id, order: index + 1 }
          ]
        })
      promises.push(
        this.$api.patch("/admin/post/image/order/update/", { images_order })
      )

      Promise.all(promises)
        .then(
          () => {},
          (err) => {
            console.log(err)
          }
        )
        .then(() => {
          this.$message.success("Позиции успешно сохранены")
        })
    },

    check2Null(arg) {
      return Object.values(arg).every((item) => !item)
    },

    validateForm() {
      if (this.check2Null(this.form.title)) {
        this.emptyTitle = true
        this.$message.error("Введите заголовок на одном из языков")
        return false
      }
      return true
    },

    async submitNews(noRouterBack) {
      if (!this.validateForm()) return

      if (this.pending) return

      if (noRouterBack === true) {
        this.pending = true
      } else {
        this.pending2 = true
      }

      try {
        let newData = cloneDeep(this.form)
        newData = {
          ...newData,
          title_ru: newData.title.ru,
          title_uz: newData.title.uz,
          title_oz: newData.title.oz,
          title_en: newData.title.en,
          title_qr: newData.title.qr
        }

        delete newData.title
        delete newData.images

        let f = new FormData()

        Object.keys(newData).forEach((key) => {
          if (newData[key] === null) {
            newData[key] = ""
          }
          f.append(key, newData[key])
          // newData[key] && f.append(key, newData[key])
          // key === "is_active" && f.append(key, newData[key])
        })
        const createOrUpdate = {
          url:
            this.form && this.form.id
              ? "/admin/common/activity/card/" + this.form.id + "/update/"
              : "/admin/common/activity/card/create/",
          method: this.form && this.form.id ? "patch" : "post",
          data: f
        }
        if (
          !(await this.$api[createOrUpdate.method](
            createOrUpdate.url,
            createOrUpdate.data,
            { timeout: 0 }
          ).then((res) => {
            if (res && res.status >= 200 && res.data && res.data.id) {
              if (res.data.id) {
                const { id } = res.data
                this.form = { ...this.form, id }
                return true
              }
            }
          }))
        ) {
          if (noRouterBack === true) {
            this.pending = false
          } else {
            this.pending2 = false
          }
          return
        }

        // upload after post done
        await this.uploadPostFiles(noRouterBack)
      } catch (error) {
        console.error(error)
        this.$message.error(
          "Возникла проблема при " +
            (this.form && this.form.id ? "сохранении" : "добавлении") +
            error
        )

        this.$sentry.captureMessage(error)
      }
    },

    async uploadPostFiles(noRouterBack = true) {
      this.pending = true

      try {
        // upload images
        await this.uploadImages()
        this.pending = false
        this.$message.success("Новость успешно сохранено")

        if (!noRouterBack) {
          // this.$router.push({
          //   name: "category",
          //   params: { cat_id: this.form.category_id }
          // })
          await this.$router.push({
            name: "flayers",
            params: {
              act_id: this.$route.params.act_id,
              type: this.$route.params.type
            }
          })
        }
      } catch (error) {
        this.pending = false
        this.$sentry.captureMessage(error)
      }
    },

    uploadImages() {
      let f = new FormData()
      this.fileList.forEach((item) => {
        f.append("images", item)
      })
      this.$api.post
    },

    fetchData() {
      this.loading = true

      if (isNaN(this.$route.params.id)) {
        this.loading = false
        return
      }

      this.$api
        .get(
          "/admin/common/activity/card/" + this.$route.params.id + "/update/"
        )
        .then(async (res) => {
          if (res && res.data) {
            const { ...data } = res.data
            this.form = {
              ...this.form,
              ...data,
              title: {
                ru: res.data.title_ru,
                qr: res.data.title_qr,
                en: res.data.title_en,
                oz: res.data.title_oz,
                uz: res.data.title_uz
              }
            }
          }
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },

    async handleImages(arg) {
      this.imageUploading = true
      this.fileList = arg
      this.image_List = arg
      let formImage = {
        card: this.$route.params.id
      }
      let newImages = arg.filter((item) => item && !item.id)
      let f = new FormData()
      Object.keys(formImage).forEach((k) => {
        f.append(k, formImage[k])
      })
      newImages.forEach((item) => {
        f.append(
          "img",
          item && item.fileTarget,
          item && item.fileTarget && item.fileTarget.name
        )
      })
      let res = await this.$api.post(
        "/admin/common/activity/card/img/create/",
        f
      )
      if (res && res.data) {
        this.form.images = res.data && res.data.images
        console.log(this.form.images)
        this.imageUploading = false
      }
    },

    activeImage(e) {
      this.photoList.forEach((item, index) => {
        this.photoList[index].is_active = item.id === e
      })
      this.$api.patch("/admin/common/activity/card/img/" + e + "/update/", {
        is_active: true
      })
    }
  }
}
</script>
<style>
.ant-modal {
  max-height: 600px;
}
.scroll-container {
  padding: 20px 0px;
}
input[type="datetime-local"] {
  border-radius: 2px;
  padding: 4px;
  border: 1px solid #c7c7c7;
}
.flex-end {
  justify-content: flex-end;
}
</style>
